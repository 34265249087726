<template>
  <Card class="px-3 py-3 flex flex-col w-full gap-3">
    <template>
      <div class="relative w-full">
        <scroll-container height="1000px">
          <Table :headers="headers" :items="items" :row-header="true">
            <template v-slot:kpis>
              <div
                class="label flex"
                v-for="(n, rating) in ratings"
                :key="rating"
              >
                <div class="mr-6 ml-4 -mt-1 text-base font-semibold w-72">
                  {{ n.rating }}
                </div>
              </div>
            </template>

            <template v-slot:item="{ item }">
              <div
                v-if="item.objective"
                class="font-normal text-base leading-5 w-80"
              >
                <span class="text-darkPurple">
                  <Textarea v-model="item.data.objective" placeholder="Objective" :height="58" />
                </span>
              </div>
              <div
                v-if="item.dispPeriod"
                class="flex flex-col justify-center items-start gap-2 whitespace-nowrap mb-4 w-36"
              >
                <div
                  class="flex justify-between items-center gap-2 ml-5 whitespace-nowrap bg-ghostWhite px-3 py-2 rounded-md"
                  v-if="item.data.dispPeriod[0].startDate !== ''"
                >
                  <span class="text-darkPurple text-sm leading-5">
                    {{
                      item.data.dispPeriod[0].startDate !== ""
                        ? $DATEFORMAT(
                            new Date(item.data.dispPeriod[0].startDate),
                            "MMMM dd, yyyy"
                          )
                        : ""
                    }}
                  </span>
                  <Icon icon-name="close" class="text-darkPurple" />
                </div>
                <div
                  class="flex justify-between items-center gap-2 ml-5 whitespace-nowrap bg-ghostWhite px-3 py-2 rounded-md"
                  v-if="item.data.dispPeriod[1].endDate !== ''"
                >
                  <span class="text-darkPurple text-sm leading-5">
                    {{
                      item.data.dispPeriod[1].endDate !== ""
                        ? $DATEFORMAT(
                            new Date(item.data.dispPeriod[1].endDate),
                            "MMMM dd, yyyy"
                          )
                        : ""
                    }}
                  </span>
                  <Icon icon-name="close" class="text-darkPurple" />
                </div>
                <div
                  @click="setTimeline(item.data.dispPeriod, item.data.period)"
                  class="cursor-pointer ml-8"
                >
                  <Icon icon-name="icon-edit" class="text-blueCrayola" />
                </div>
              </div>
              <div
                v-if="item.kpis"
                class="flex"
              >
                <div
                  class="mr-6 ml-4 -mt-1 text-base w-72"
                  v-for="(value, i) in item.data.kpis"
                  :key="`${i}`"
                >
                  <Textarea v-model="item.data.kpis[i].criteria" :placeholder="`Enter KPI`" :height="58"/>
                </div>
              </div>
              <div
                v-if="item.weight"
                class="flex flex-col justify-start items-start whitespace-nowrap w-36 mr-5"
              >
                <div class="flex justify-start items-center gap-2">
                  <TextInput
                    type="number"
                    v-model="item.data.weight"
                    :placeholder="`Weight`"
                    class="text-darkPurple text-base -mt-4 leading-5 w-32"
                  />
                </div>
              </div>
            </template>
          </Table>
        </scroll-container>
      </div>
    </template>
    <TimelineModal :open-modal="timelineModal" @selected="saveTimeline($event)" @close="timelineModal = false" />
  </Card>
</template>

<script>
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Textarea from "@scelloo/cloudenly-ui/src/components/text-area";
import TextInput from "@scelloo/cloudenly-ui/src/components/text";
import Table from "@/components/Table";
import Icon from "@/components/Icon";
import ScrollContainer from "@/components/ScrollContainer";
import TimelineModal from "../Modals/TimelineModal";

export default {
  name: "SatisfactoryPerformance",
  components: {
    Textarea,
    ScrollContainer,
    TextInput,
    Card,
    Icon,
    Table,
    TimelineModal,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    ratings: {
      type: Array,
      required: true,
      default: () => [],
    },
    message: {
      type: String,
    },
  },
  data() {
    return {
      timelineModal: false,
      headers: [
        { title: "Performance Improvement Action Plan", value: "objective" },
        { title: "Timeline", value: "dispPeriod" },
        { title: "Success Criteria (KPI)", value: "kpis" },
        { title: "Weight (%) = 100%", value: "weight" },
      ],
      current: {},
      payloadTimeline: {},
    };
  },

  methods: {
    setTimeline(disTimeline, timeline) {
      this.timelineModal = true;
      this.current = disTimeline;
      this.payloadTimeline = timeline;
    },

    formatDate(value) {
      const month = this.$DATEFORMAT(new Date(value), "MM");
      const day = this.$DATEFORMAT(new Date(value), "dd");
      const year = this.$DATEFORMAT(new Date(value), "yyyy");

      return `${year}-${month}-${day}`;
    },

    saveTimeline(value) {
      this.current[0].startDate = value.from;
      this.current[1].endDate = value.to;

      this.payloadTimeline[0].startDate = this.formatDate(value.from);
      this.payloadTimeline[1].endDate = this.formatDate(value.to);

      this.timelineModal = false;
    },

    setPayload() {
      const payload = this.items;

      this.$emit('set', payload);
    }
  },

  mounted() {
        setInterval(() => {
          this.setPayload();
        }, 10000);
  }
};
</script>
<style lang="scss">
.tracking-0015 {
  letter-spacing: 0.015em;
}
.summaryTable {
  tbody {
    tr {
      border-top: 1px dashed #878e99 !important;
    }
    tr:first-child {
      border-top: 0 !important;
    }
  }
  .head {
    justify-content: center !important;
  }
}
tbody {
  tr {
    background: white !important;
    border-bottom: 1px dashed #878e99 !important;
  }
  tr:nth-last-child(-n + 2) {
    border-bottom: 0 !important;
  }
}
.width-272px {
  width: 272px;
}
.width-1414px {
  width: 1414px !important;
}
</style>
