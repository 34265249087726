<template>
    <div>
      <Table
        :headers="headers"
        :items="items"
        aria-label="PerformanceSections"
        :has-number="true"
      >
        <template v-slot:item="{ item }">
        <ValidationObserver rules="required">
          <div v-if="item.performanceIssue" class="flex justify-start items-center gap-2">
            <span class="text-darkPurple font-normal text-sm leading-5 label">
              <CTextarea v-model="item.data.performanceIssue" :height="58"
              placeholder="Performance Issue"
              :rules="['required']"
              />
            </span>
            <div
              class="text-white text-base cursor-pointer"
              @click="uploadModal = !uploadModal"
            >
              <Icon icon-name="icon-attachment" size="xs" />
            </div>
          </div>
          <div
            v-if="item.dispPeriod"
            class="flex flex-col justify-start items-start"
          >
            <div class="flex justify-center items-center gap-2">
              <div class="date-flex w-full">
                <datepicker
                  placeholder="--Select Day & Month--"
                  style="width: 100%; outline: none"
                  input-class="date-input"
                  format="dd/MMMM"
                  @selected="setStart($event, item.data.period, item.data.dispPeriod)"
                  v-model="item.data.dispPeriod[0].startDate"
                />
              </div>
              <div class="flex self-center">
                <span
                  class="font-black text-xs leading-5 text-darkPurple uppercase"
                  >to</span
                >
              </div>
              <div class="date-flex w-full">
                <datepicker
                  placeholder="--Select Day & Month--"
                  style="width: 100%; outline: none"
                  input-class="date-input"
                  format="dd/MMMM"
                  @selected="setEnd($event, item.data.period, item.data.dispPeriod)"
                  v-model="item.data.dispPeriod[1].endDate"
                />
              </div>
            </div>
          </div>
          <div v-if="item.impact" class="font-normal text-base leading-5">
            <span class="text-darkPurple">
              <CTextarea v-model="item.data.impact" :height="58"
              placeholder="Impact"
              :rules="['required']"
              />
            </span>
          </div>
          </ValidationObserver>
        </template>
      </Table>
    <UploadModal :open-modal="uploadModal" @close="uploadModal = false" />
    </div>
    </template>

<script>
import { ValidationObserver } from "vee-validate";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import Datepicker from "vuejs-datepicker";
import Icon from "@/components/Icon";
import UploadModal from "../Modals/UploadModal";

export default {
  name: "ESSSpecificIndicators",
  components: {
    ValidationObserver,
    Datepicker,
    Table,
    CTextarea,
    Icon,
    UploadModal,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    message: {
      type: String,
    },
  },
  data() {
    return {
      uploadModal: false,
      headers: [
        { title: "Performance Issue", value: "performanceIssue" },
        { title: "Period", value: "dispPeriod" },
        { title: "Impact", value: "impact" },
      ],
      current: {},
      payloadTimeline: {},
    };
  },

  methods: {
    setStart(value, timeline, prevTimeline) {
      this.current = timeline;
      this.payloadTimeline = prevTimeline;

      this.current[0].startDate = this.formatDate(value);
      this.payloadTimeline[0].startDate = value;
    },

    setEnd(value, timeline, prevTimeline) {
      this.current = timeline;
      this.payloadTimeline = prevTimeline;

      this.current[1].endDate = this.formatDate(value);
      this.payloadTimeline[1].endDate = value;
    },

    formatDate(value) {
      const month = this.$DATEFORMAT(new Date(value), "MM");
      const day = this.$DATEFORMAT(new Date(value), "dd");
      const year = this.$DATEFORMAT(new Date(value), "yyyy");

      return `${year}-${month}-${day}`;
    },

    setPayload() {
      const payload = this.items;

      this.$emit('set', payload);
    }
  },

  mounted() {
        setInterval(() => {
          this.setPayload();
        }, 10000);
  }
};
</script>
<style lang="scss">
.tracking-0015 {
  letter-spacing: 0.015em;
}
tbody {
  tr {
    background: white !important;
    border-bottom: 1px dashed #878e99 !important;
  }
  tr:nth-last-child(-n + 2) {
    border-bottom: 0 !important;
  }
}
</style>
