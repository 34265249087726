<template>
  <RightSideBar
    v-if="openModal"
    @close="$emit('close')"
    submit="Save"
    @submit="saveTimeline"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <span class="text-darkPurple text-lg font-bold leading-5">
       Timeline
      </span>
    </template>
    <template v-slot:subtitle>
      <div class="w-full my-5">
        <span class="font-semibold text-base leading-5 text-darkPurple">
          Select appropriate dates for the timeline(s)
        </span>
      </div>
    </template>
    <template>
      <div class="w-full flex flex-col justify-start items-start gap-5">
        <span class="font-semibold text-base leading-5 text-darkPurple -mb-4">
          Dates
        </span>
        <div class="flex flex-col justify-start items-start gap-5 w-full">
          <div class="flex w-full justify-between items-center gap-5">
                <div class="date-flex w-full">
                  <datepicker
                    placeholder="--Select Day & Month--"
                    style="width: 100%; outline: none"
                    input-class="date-input"
                    v-model="startDate"
                  />
                </div>
          </div>
          <div class="flex w-full justify-between items-center gap-5">
                <div class="date-flex w-full">
                  <datepicker
                    placeholder="--Select Day & Month--"
                    style="width: 100%; outline: none"
                    input-class="date-input"
                    v-model="endDate"
                  />
                </div>
          </div>
        </div>
      </div>
    </template>
  </RightSideBar>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import RightSideBar from "@/components/RightSideBar";

export default {
  components: {
    RightSideBar,
    Datepicker,
  },

  props: {
    openModal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      startDate: '',
      endDate: '',
    }
  },

  watch: {
    startDate(value) {
      return new Date(value).toISOString();
    },

    endDate(value) {
      return new Date(value).toISOString();
    },
  },

  methods: {
    saveTimeline() {
      const timeline = {
        from: this.startDate,
        to: this.endDate,
      };

      this.$emit('selected', timeline);
    }
  }
};
</script>

<style>
  .rightSidebar{
    width: 520px !important;
  }
</style>