<template>
  <Card class="px-3 py-3 flex flex-col w-full gap-3">
    <template>
      <div class="flex justify-start items-center gap-2">
        <h1 class="font-bold text-lg leading-6 tracking-0015 text-flame">
          Section 3:
        </h1>
        <h2 class="font-bold text-lg leading-6 tracking-0015 text-darkPurple">
          Previous Efforts to Avert a Below-Standard Performance
        </h2>
      </div>
      <div
        class="w-full flex p-2 rounded-md bg-yellow-50 border border-yellow-500"
      >
        <div class="text-carrotOrange mx-auto self-center">
          <Icon size="xs" icon-name="icon-info" class="text-carrotOrange" />
        </div>
        <span class="flex text-sm mx-3">{{ message }}</span>
        <div class="text-carrotOrange mx-auto self-center">
          <Icon size="xs" icon-name="close" class="text-carrotOrange" />
        </div>
      </div>
    </template>
    <template>
      <Table
        :headers="headers"
        :items="items"
        aria-label="PerformanceSections"
        :has-number="true"
      >
        <template v-slot:item="{ item }">
          <div v-if="item.performanceIssue" class="flex justify-start items-center gap-2">
            <span class="text-darkPurple font-normal text-base leading-5">
              {{ item.data.performanceIssue === '' ? '----' :  item.data.performanceIssue}}
            </span>
            <div
              class="text-flame text-base cursor-pointer"
              @click="uploadModal = !uploadModal"
              v-if="item.data.performanceIssue !== ''"
            >
              <Icon icon-name="attachment" size="xs" />
            </div>
          </div>
          <div
            v-if="item.correction"
            class="font-normal text-base leading-5 text-darkPurple"
          >
            <Textarea v-model="item.data.correction" :height="58" :disabled="item.data.performanceIssue === ''" />
          </div>
          <div
            v-if="item.dispPeriod"
            class="flex flex-col justify-start items-start"
          >
            <div class="flex justify-center items-center gap-2">
              <div class="date-flex w-full">
                <datepicker
                  placeholder="--Select Day & Month--"
                  style="width: 100%; outline: none"
                  input-class="date-input"
                  format="dd/MMMM"
                  @selected="setStart($event, item.data.period, item.data.dispPeriod)"
                  v-model="item.data.dispPeriod[0].startDate"
                />
              </div>
              <div class="flex self-center">
                <span
                  class="font-black text-xs leading-5 text-darkPurple uppercase"
                  >to</span
                >
              </div>
              <div class="date-flex w-full">
                <datepicker
                  placeholder="--Select Day & Month--"
                  style="width: 100%; outline: none"
                  input-class="date-input"
                  format="dd/MMMM"
                  @selected="setEnd($event, item.data.period, item.data.dispPeriod)"
                  v-model="item.data.dispPeriod[1].endDate"
                />
              </div>
            </div>

          </div>
        </template>
      </Table>
    </template>
    <UploadModal :open-modal="uploadModal" />
  </Card>
</template>

<script>
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Textarea from "@scelloo/cloudenly-ui/src/components/text-area";
import Datepicker from "vuejs-datepicker";
import Icon from "@/components/Icon";
import UploadModal from "../Modals/UploadModal";

export default {
  name: "BelowPerformance",
  components: {
    Card,
    Table,
    Textarea,
    Datepicker,
    Icon,
    UploadModal,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    message: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      uploadModal: false,
      headers: [
        { title: "Performance Issue", value: "performanceIssue", width: 30 },
        { title: "Previous Correctional Efforts", value: "correction" },
        { title: "Period", value: "dispPeriod" },
      ],
      current: {},
      payloadTimeline: {},
    };
  },

  methods: {
    setStart(value, timeline, prevTimeline) {
      this.current = timeline;
      this.payloadTimeline = prevTimeline;

      this.current[0].startDate = this.formatDate(value);
      this.payloadTimeline[0].startDate = value;
    },

    setEnd(value, timeline, prevTimeline) {
      this.current = timeline;
      this.payloadTimeline = prevTimeline;

      this.current[1].endDate = this.formatDate(value);
      this.payloadTimeline[1].endDate = value;
    },

    formatDate(value) {
      const month = this.$DATEFORMAT(new Date(value), "MM");
      const day = this.$DATEFORMAT(new Date(value), "dd");
      const year = this.$DATEFORMAT(new Date(value), "yyyy");

      return `${year}-${month}-${day}`;
    },

    setPayload() {
      const payload = this.items;

      this.$emit('set', payload);
    }
  },

  mounted() {
        setInterval(() => {
          this.setPayload();
        }, 10000);
  }
};
</script>
<style lang="scss">
.tracking-0015 {
  letter-spacing: 0.015em;
}
tbody {
  tr {
    background: white !important;
    border-bottom: 1px dashed #878e99 !important;
  }
  tr:nth-last-child(-n + 2) {
    border-bottom: 0 !important;
  }
}
.width-272px {
  width: 272px;
}

.vdp-datepicker__calendar {
  z-index: 9999;
}
</style>
